import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import Meta from "vue-meta"
import inViewportDirective from "vue-in-viewport-directive"
import VueSmoothScroll from 'vue2-smooth-scroll'

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(VueSmoothScroll, {
  duration: 300,
  updateHistory: false,
  easingFunction: `linear`
})

inViewportDirective.defaults.margin = '-25% 0%'
Vue.directive("in-viewport", inViewportDirective)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
