import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import(/* webpackChunkName: "page" */ "./views/Home.vue"),
      meta: {
        title: "Waites Tree Surveys | Tree Surveys Grimsby",
        description: "We are a family run tree survey and reports company based in Grimsby and have been established for 15 years."
      }
    },
    {
      path: "/about",
      name: "about",
      component: () => import(/* webpackChunkName: "page" */ "./views/About.vue"),
      meta: {
        title: "About Waites Tree Surveys | Tree Surveys Grimsby",
        description: "We are a family run tree survey and reports company based in Grimsby and have been established for 15 years."
      }
    },
    {
      path: "/services",
      name: "services",
      component: () => import(/* webpackChunkName: "page" */ "./views/Services.vue"),
      meta: {
        title: "Waites Tree Surveys Services | Tree Surveys Grimsby",
        description: "We are a family run tree survey and reports company based in Grimsby and have been established for 15 years."
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import(/* webpackChunkName: "page" */ "./views/Contact.vue"),
      meta: {
        title: "Contact Waites Tree Surveys | Tree Surveys Grimsby",
        description: "We are a family run tree survey and reports company based in Grimsby and have been established for 15 years."
      }
    },
    {
      path: "/bs5837-tree-reports",
      name: "bs5837",
      component: () => import(/* webpackChunkName: "page" */ "./views/BS5837.vue"),
      meta: {
        title: "BS5837 Tree Reports | Tree Surveys Grimsby",
        description: "We are a family run tree survey and reports company based in Grimsby and have been established for 15 years."
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
