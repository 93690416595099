<template>
  <div id="app" :class="showMenu ? 'limit' : ''">
    <header id="header">
      <div class="inner">
        <a aria-label="Menu" href class="menu-link" @click.prevent="showMenu = !showMenu">
          <transition name="fade">
            <span class="material-icons-outlined" v-if="!showMenu">menu</span>
            <span class="material-icons-outlined" v-else>close</span>
          </transition>
        </a>

        <router-link to="/" class="logo"
          ><img src="/images/waites-tree-surveys-full.png" class="full" alt="Tree Surveys Grimsby"/><img
            src="/images/waites-tree-surveys-cropped.png"
            class="cropped"
            alt="Tree Surveys Grimsby"
        /></router-link>
        <nav :class="showMenu ? 'show' : ''">
          <div class="nav-links">
            <div>
              <router-link to="/" @click.native="closeMenu">Home</router-link>
              <router-link to="/about" class="spaced" @click.native="closeMenu">About Us</router-link>
              <router-link to="/services" @click.native="closeMenu">Services</router-link>
              <router-link to="/contact" @click.native="closeMenu">Contact</router-link>
            </div>
          </div>
        </nav>
        <div class="social">
          <a class="nomobile" aria-label="Email" href="mailto:info@waitestreesurveys.co.uk"
            ><span class="material-icons-outlined">
              mail_outline
            </span></a
          >
          <a aria-label="Telephone" class="mobile" href="tel:07779991578"
            ><span class="material-icons-outlined">call</span></a
          >
          <a
            class="nomobile instagram"
            aria-label="Instagram"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/waites_trees_and_gardens/"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z"
                fill="currentColor"
              /></svg
          ></a>
          
        </div>
      </div>
    </header>
    <div class="banners" :class="[$route.name == 'index' ? 'max' : 'min', $route.name]">
      <div class="inner">
        <div class="banner-content">
          <div class="banner-caption">
            <div v-if="$route.name == 'index'">
              <h1>Tree Surveyors you can trust.</h1>
              <h2>
                We are Arboricultural Consultants here to provide reliable and comprehensive tree reports &amp; surveys throughout Grimsby and Lincolnshire.
              </h2>
              <a href @click.prevent="scrollFix('#content')" class="banner-more"
                ><span class="material-icons-outlined"> arrow_downward </span>Read more
              </a>
            </div>
            <div v-if="$route.name == 'about'">
              <h1>Who we are &amp; why trust us</h1>
            </div>
            <div v-if="$route.name == 'services'">
              <h1>Expert services at competitive prices</h1>
            </div>
            <div v-if="$route.name == 'contact'">
              <h1>Speak with an experienced arborist today</h1>
            </div>
            <div v-if="$route.name == 'bs5837'">
              <h1>BS 5837 Tree Report Specialists</h1>
            </div>
          </div>
          <div v-if="$route.name == 'index'">
            <div class="quote">
              <div class="quote-heading">
                Get a quick quote
              </div>
              <p class="quote-para">Fill in our simple form to get a fast, free, no-obligation quote.</p>
              <form class="quote-form" data-netlify="true" data-netlify-honeypot="bot-field" method="post" name="contact-form">
                <input name="form-name" required type="hidden" value="contact-form" />
                <input name="Name" required type="text" placeholder="Name *" />
                <input name="Number" required type="text" placeholder="Contact Number *" />
                <input name="Address" type="text" placeholder="Address" />
                <textarea name="Message" required placeholder="Message *"></textarea>
                <button type="submit"><img class="ripple" src="/images/ripple.svg" alt="Get a tree survey quote" /> Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view :key="$route.fullPath" />

    <div class="logos" v-show="$route.name == 'index'" v-in-viewport.once>
      <img src="/images/logos.jpg" alt="Tree Reports Grimsby" />
    </div>

    <div class="cta" v-in-viewport.once>
      <div class="inner">
        <div class="cta-wrapper">
          <div class="cta-heading">Need a tree survey?</div>
          <div class="cta-sub">Call us on <a href="tel:07779991578">07779991578</a> for expert advice</div>
        </div>
      </div>
    </div>
    <div class="reviews" v-in-viewport.once>
      <div class="inner">
        <div class="reviews-grid">
          <div
            :key="review.name"
            class="review"
            v-for="review in shuffleArray(reviews).slice(0, $route.name == 'index' ? 8 : 4)"
          >
            <p class="review-quote">{{ review.quote }}</p>
            <p class="review-name">{{ review.name }}</p>
            <img src="/images/g-stars-alt.png" alt="5 star reviews" />
          </div>
        </div>
      </div>
    </div>
    <section class="links" v-if="$route.name == 'index'">
      <div class="inner">
        <div class="links-grid">
          <router-link v-in-viewport.once to="/about"
            ><img src="/images/about-tree-surveys.jpg" alt="About Tree Surveys" /><span class="link-heading"
              >About Our Surveyors</span
            ><span class="link-desc">More about who we are and why you can trust our tree reports</span></router-link
          >
          <router-link v-in-viewport.once to="/services"
            ><img src="/images/tree-survey-services.jpg" alt="Our Tree Services" /><span class="link-heading"
              >Tree Survey Pricing</span
            ><span class="link-desc"
              >Transparent and competitive pricing for a full range of surveys and reports</span
            ></router-link
          >
          <router-link v-in-viewport.once to="/bs5837-tree-reports"
            ><img src="/images/tree-reports.jpg" alt="BS 5837 Tree Reports" /><span class="link-heading"
              >BS 5837 Tree Reports</span
            ><span class="link-desc"
              >Surveying to the British Standard for trees in relation to construction</span
            ></router-link
          >
          <router-link v-in-viewport.once to="/contact"
            ><img src="/images/tree-reports-contact.jpg" alt="Arboricultural Reports" /><span class="link-heading"
              >Get a quote</span
            ><span class="link-desc">Get your fast, free, no-obligation tree survey quote today</span></router-link
          >
        </div>
      </div>
    </section>

    <div class="forest">
      <img
        :src="$route.name == 'index' ? '/images/tree-reports-forest.jpg' : '/images/tree-reports-forest-small.jpg'"
        alt="Tree Surveys Grimsby"
      />
    </div>

    <footer id="footer">
      <div class="inner">
        <div class="footer-col our-services">
          <div class="footer-header">What we do</div>

          <p>
            We provide reliable, timely, and expertly crafted arboricultural reports. Covering <strong>Grimsby</strong>, <strong>Immingham</strong>, <strong>Scunthorpe</strong>, <strong>Brigg</strong>, <strong>Louth</strong>, <strong>Barton On Humber</strong>, <strong>Hull</strong> and many more areas of Lincolnshire
          </p>
        </div>
        <div class="footer-col">
          <div class="footer-header">Contact Us</div>

          <a href="mailto:info@waitestreesurveys.co.uk"
            ><span class="material-icons-outlined"> mail </span> info@waitestreesurveys.co.uk</a
          >
          <a href="tel:07779991578"><span class="material-icons-outlined"> call </span> 07779991578</a>
        </div>
        <div class="footer-col">
          <div class="footer-header">Follow Us</div>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/waites_trees_and_gardens/"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z"
                fill="currentColor"
              />
            </svg>
            Instagram</a
          >
        </div>
        <router-link to="/" class="footer-logo">
          <img src="/images/waites-tree-surveys-full.png" alt="Waites Tree Surveys Grimsby" />
        </router-link>
      </div>
    </footer>
    <div class="legal">
      <div class="inner">
        <router-link to="/">Privacy Policy</router-link>
        <div>&copy; Waites Trees &amp; Gardens <span>|</span> Ladysmith Road, Grimsby</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      reviews: [
        {
          quote:
            "Very knowledgeable, efficient and pleasant to deal with. No hesitation in using again in the future or recommending to others.",
          name: "Vanessa Wrench"
        },
        {
          quote:
            "Their expertise is top tier and they took the time to provide excellent advice. Would definitely recommend their services without hesitation.",
          name: "Elizabeth De Paolis"
        },
        {
          quote:
            "Extremely knowledgeable in all things tree related and incredibly accommodating, squeezing us in their schedule to help meet our deadlines.",
          name: "Emma Taylor"
        },
        {
          quote:
            "Using this company was stress free and both Joe and Emily proved to be exceptionally trustworthy & reliable. Would not hesitate to use again.",
          name: "Marcus Atkinson"
        },
        {
          quote:
            "We will definitely be using Waite's again and we would recommend them wholeheartedly to other users. Great work, and at a fair price.",
          name: "Keith Lewindon"
        },
        {
          quote:
            "Very professional. They got straight on with the job and made sure I was happy with the finished result. I would definitely recommend them.",
          name: "Tara Farrington"
        },
        {
          quote:
            "We were recommended Waites Trees and we were delighted with the service. Joe is very professional and gives good honest sound advice.",
          name: "Susan Brown"
        },
        {
          quote:
            "They carried out the work to a very high standard. Will definitely use them again and I unhesitatingly recommend them for any tree work.",
          name: "David Banks"
        }
      ]
    }
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => document.getElementById(hash.replace("#", "")).scrollIntoView({ behavior: "smooth" }), 1)
    },
    closeMenu: function() {
      this.showMenu = false
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }
  },
  created: function() {
    window.onscroll = function() {
      var header = document.getElementById("header")

      if (window.pageYOffset > 0) {
        header.classList.add("scrolled")
      } else {
        header.classList.remove("scrolled")
      }
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: [{ name: "description", content: this.$route.meta.description }]
    }
  },
  watch: {
    $route() {
      this.showMenu = false
    }
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Koh+Santepheap:wght@400;700&family=Open+Sans:wght@400;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

*:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  line-height: 1.5;
}

html {
  font-size: 62.5%;
  color: #272425;
}

body,
input,
select,
textarea,
button {
  font-family: "Open Sans", sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
}

.inner {
  display: table;
  margin: auto;
  max-width: 170rem;
  width: 92%;
  position: relative;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  color: white;
  font-family: "Koh Santepheap", cursive;
  font-size: 1.8rem;
  transition: all 0.5s;
}
.logo .cropped {
  display: none;
}
.content ol {
  padding-left:4rem;
}
header.scrolled {
  background: #006666;
  box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.75);
}
header.scrolled .logo .cropped {
  display: block;
}
header.scrolled .logo .full {
  display: none;
}
header.scrolled .logo {
  top: 0;
  padding: 1rem 0;
  transform: scale(0.8);
}
header.scrolled .social {
  top: 2.2rem;
}
header.scrolled nav {
  top: 3rem;
}
header.scrolled .menu-link {
  top: 2.2rem;
}
.logo {
  display: block;
  width: 15rem;
  position: relative;
  top: -2.5rem;
  z-index: 2;
  transition: all 0.5s;
}
.banners {
  width: 100%;
  z-index: 1;
  min-height: 45rem;
  height: 45rem;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  background-color: rgb(14, 18, 21);
  background-image: url(/images/tree-surveys-lincolnshire.jpg);
  transition: all 0.5s;
}

.banners.contact {
  background-image: url(/images/tree-surveys-banner.jpg);
}

.banners.services {
  background-image: url(/images/tree-reports-services.jpg);
}

.banners.about {
  background-image: url(/images/tree-reports-about.jpg);
}

.banners.max {
  height: 100vh;
  min-height: 75rem;
}

.menu-link {
  transition: all 0.5s;
}

nav {
  position: absolute;
  left: 50%;
  top: 7.5rem;
  transform: translateX(-50%);
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  z-index: 1;
  transition: all 0.5s;
}

nav a {
  padding: 1rem 4rem;
}

.social {
  position: absolute;
  top: 6.5rem;
  right: 0;
  z-index: 2;
  transition: all 0.5s;
}

.social a {
  font-size: 3rem;
  padding: 0 2rem;
  display: inline-block;
}

.instagram,
.facebook {
  transform: scale(0.8);
  position: relative;
  bottom: -0.2rem;
}

.facebook {
  bottom: 0.3rem;
}

.banner-content {
  color: white;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 8rem;
  margin-top: 5rem;
}

.banner-caption {
  display: flex;
  align-items: center;
}

.max .banner-content {
  color: white;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 8rem;
  margin-top: 5rem;
}

.min .banner-content {
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8rem;
  margin-top: 5rem;
}

.min .banner-caption {
  height: 45vh;
  min-height: 50px;
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.banner-more {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
  display: inline-block;
}

.banner-more span {
  position: relative;
  top: 0.5rem;
  margin-right: 1rem;
  line-height: 0;
}

h1 {
  font-family: "Koh Santepheap", cursive;
  font-size: 5rem;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 1);
  line-height: 1.1;
  margin-bottom: 2rem;
}

.min h1 {
  font-size: 4rem;
}

h2 {
  font-weight: normal;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 1);
  line-height: 1.4;
}

.quote {
  background: rgba(0, 102, 102, 0.9);
  border-radius: 0.5rem;
  padding: 3rem;
  box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.75);
}

.quote-heading {
  font-family: "Koh Santepheap", cursive;
  font-size: 3rem;
  font-weight: bold;
}

.quote-form input,
.quote-form textarea {
  width: 100%;
  margin-top: 2rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: 0;
}

.quote-form textarea {
  height: 12rem;
}

.quote-form button {
  background: #c53931;
  color: white;
  border: 0;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.quote-form button img {
  height: 2.5rem;
  margin-bottom: -0.65rem;
  margin-right: 1rem;
  filter: brightness(1.5);
}

.split {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 5rem;
  margin: 10rem 0;
}

.no-split {
  margin: 18rem 0;
  transition: all 1s;
}

.no-split.in-viewport {
  margin: 10rem 0;
}

.split img {
  border-radius: 0.5rem;
}
.split-left {
  margin-top: 5rem;
  transition: all 1s;
  opacity: 0;
}
.split-right {
  margin-top: 8rem;
  transition: all 1s;
  opacity: 0;
}
.split-left.in-viewport,
.split-right.in-viewport {
  margin-top: 0;
  opacity: 1;
}
.content h3 {
  font-family: "Koh Santepheap", cursive;
  font-size: 3rem;
  color: rgba(0, 102, 102, 1);
  line-height: 1.2;
}
.content h4 {
  font-family: "Koh Santepheap", cursive;
  font-size: 2.2rem;
  color: rgba(0, 102, 102, 1);
  line-height: 1.2;
  margin-top: 3rem;
}
.content p {
  margin-top: 2rem;
}
.content ul {
  margin-top: 2rem;
  margin-left: 0;
  list-style-type: none;
}
.content ul span {
  margin-right: 1rem;
  position: relative;
  color: #c53931;
  bottom: -0.5rem;
}

.anchor {
  position: relative;
  top: -8rem;
  display: block;
}

.anchorsmall {
  position: relative;
  top: -14rem;
  display: block;
}

#footer {
  background: #006666;
  color: white;
  padding: 6rem 0;
  padding-top: 8rem;
}

#footer .facebook {
  transform: scale(0.9);
}

.reviews {
  padding-top: 8rem;
  padding-bottom: 4rem;
  opacity: 0;
  transition: all 1s;
}
.reviews-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6rem;
  font-size: 1.4rem;
}
.reviews img {
  height: 2rem;
}
.review-quote {
  margin-bottom: 1rem;
}
.review-name {
  margin-bottom: 1rem;
  font-weight: bold;
}

.reviews.in-viewport,
.cta.in-viewport {
  opacity: 1;
  margin-top: 0;
}

.cta {
  background: #c53931;
  color: white;
  text-align: center;
  padding: 3rem;
  margin-top: 5rem;
  opacity: 0;
  transition: all 1s;
}
.cta-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  padding: 2rem;
  border-radius: 0.5rem;
}
.cta-heading {
  font-size: 3rem;
  font-family: "Koh Santepheap", cursive;
}
.cta-sub {
  font-size: 2rem;
}
.cta-sub a {
  font-weight: bold;
}

.legal {
  background: #c53931;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.3rem;
  padding-bottom: 3rem;
}

.legal a:hover {
  color: white;
}

.legal .inner {
  padding-top: 3rem;
}

.legal span {
  display: inline-block;
  margin: 0 1rem;
}

.legal a {
  float: right;
}

.footer-col {
  float: left;
  margin-right: 10rem;
  margin-bottom: 4rem;
}

.footer-col.our-services {
  max-width: 30rem;
}

.footer-col a {
  display: block;
  margin-bottom: 1rem;
}

.footer-col a span {
  position: relative;
  bottom: -0.5rem;
  margin-right: 0.75rem;
}
.footer-col a svg {
  margin-right: 0.75rem;
  width: 24px;
  height: 24px;
  margin-bottom: -0.5rem;
}

.footer-header {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-family: "Koh Santepheap", cursive;
}

.footer-logo {
  width: 20rem;
  display: block;
  float: right;
  position: relative;
  top: -3rem;
  margin-bottom: -5rem;
}

.nptc {
  width: 10rem;
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
}

.links {
  padding: 8rem 0;
}
.links a:hover img {
  transform: scale(1.05);
}
.links-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6rem;
}
.links img {
  border-radius: 0.5rem;
  transition: all 0.3s;
}
.link-heading {
  font-family: "Koh Santepheap", cursive;
  color: #006666;
  font-size: 2.2rem;
  display: block;
  width: 100%;
  position: relative;
  margin-top: 1rem;
  transition: all 0.3s;
  line-height: 1.1;
}
.links a:hover .link-heading {
  color: #c53931;
}
.forest,
.logos {
  text-align: center;
  line-height: 0;
}

.logos {
  padding-bottom: 4rem;
  opacity: 0;
  transition: all 1s;
}

.logos img {
  max-width: 90%;
  margin: auto;
}

.logos.in-viewport {
  opacity: 1;
  padding-bottom: 8rem;
}

.links a {
  transition: all 1s;
  opacity: 0;
}
.links a:nth-child(1) {
  margin-top: 4rem;
  transform: rotateY(30deg);
}
.links a:nth-child(2) {
  margin-top: 5rem;
  transform: rotateY(40deg);
}
.links a:nth-child(3) {
  margin-top: 6rem;
  transform: rotateY(50deg);
}
.links a:nth-child(4) {
  margin-top: 7rem;
  transform: rotateY(60deg);
}
.links a.in-viewport {
  margin-top: 0;
  opacity: 1;
  transform: rotateY(0);
}

.cta {
  background: #c53931;
  color: white;
  text-align: center;
  padding: 3rem;
  margin-top: 5rem;
  opacity: 0;
  transition: all 1s;
}
.cta-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  padding: 2rem;
  border-radius: 0.5rem;
}
.cta-heading {
  font-size: 3rem;
  font-family: "Koh Santepheap", cursive;
}
.cta-sub {
  font-size: 2rem;
}
.cta-sub a {
  font-weight: bold;
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 55%;
  }
}

@media screen and (min-width: 950px) {
  .mobile {
    display: none !important;
  }
  .menu-link {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .reviews-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
  nav {
    position: fixed;
    transform: none;
    top: 0;
    left: 100%;
    transition: all 0.5s;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: rgba(0, 102, 102, 1);
    display: flex;
    align-items: center;
    pointer-events: all;
  }
  nav.show {
    left: 0;
  }
  nav .nav-links {
    display: block;
    width: 100%;
  }
  nav a {
    display: block;
    width: 100%;
    padding: 0;
    transition: all 0.7s;
    font-size: 2.5rem;
  }
  nav.show a {
    padding: 2rem 0;
  }
  .header {
    text-align: center;
  }
  .logo {
    margin: auto;
    z-index: 10;
    position: relative;
  }
  .menu-link {
    position: absolute;
    top: 7.25rem;
    z-index: 10;
  }
  header.scrolled .menu-link {
    top: 2.8rem;
  }
  .nomobile {
    display: none !important;
  }
  .banners.max {
    min-height: 100vh;
    height: auto;
    display: block;
    padding: 0;
    background-position: 60%;
  }
  .max .banner-content {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: 0;
    gap: 0;
  }
  .max .banner-caption {
    min-height: 80vh;
    padding: 20rem 4rem 10rem 4rem;
    background-image: url(/images/tree-surveys-Grimsby.jpg);
    background-size: cover;
    background-position: center 50%;
  }
  .banners .inner {
    width: 100%;
    max-width: 100%;
  }
  .quote {
    background: #f5f5f5;
    border-radius: 0;
    color: #272425;
    padding: 4rem 5%;
    padding-bottom: 5rem;
  }
  .quote-heading {
    color: #c53931;
  }

  .anchor {
    top: -6.8rem;
  }

  .footer-col {
    float: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5rem;
    text-align: center;
  }
  .footer-col.our-services {
    display: none;
  }
  .footer-logo {
    margin: auto;
    top: 0;
    float: none;
  }
}
@media screen and (max-width: 850px) {
  .split {
    grid-template-columns: 1fr;
    margin: 5rem 0;
  }
  .links {
    padding: 5rem 0;
  }
  .links-grid {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
  .link-heading {
    font-size: 1.8rem;
  }
  .links a:nth-child(1) {
    margin-top: 4rem;
  }
  .links a:nth-child(2) {
    margin-top: 4rem;
  }
  .links a:nth-child(3) {
    margin-top: 4rem;
  }
  .links a:nth-child(4) {
    margin-top: 4rem;
  }
  .links a.in-viewport {
    margin-top: 0;
    opacity: 1;
  }
  .nptc {
    margin: auto;
    display: block;
  }
  
.anchorsmall {
  top: -10rem;
}
}
@media screen and (max-height: 500px) {
  nav a {
    display: inline-block;
    width: auto;
    margin: 1rem 2rem;
  }
}
@media screen and (max-width: 500px) {
  .social a {
    padding: 0 1.2rem;
  }
  .logo {
    width: 10rem;
    top: -1rem;
  }
  .social {
    top: 4rem;
  }
  .menu-link {
    top: 4.5rem;
  }
  header.scrolled .social {
    top: 1.4rem;
  }
  header.scrolled .menu-link {
    top: 1.8rem;
  }
  h1 {
    font-size: 4rem;
    max-width: 70%;
    margin: auto;
    margin-bottom: 2rem;
  }
  .min h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    max-width: 80%;
    margin: auto;
  }

  .legal {
    text-align: center;
  }
  .legal a {
    float: none;
    display: inline-block;
    margin-bottom: 1rem;
  }
  .legal span {
    display: block;
    opacity: 0;
    margin-bottom: -1rem;
  }
  .links-grid {
    gap: 2rem;
  }
  .link-desc {
    display: none;
  }
  .reviews-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}
</style>
